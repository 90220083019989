export default (() => {
  class MedicareSimplified {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }

      const { cssUrl, jsUrl } = this.elems[0].dataset
      this.CSS = cssUrl
      this.JS = jsUrl
      this.medicareElement = this.elems[0]

      window.wafer.ready(() => {
        this.init()
      })
    }

    init() {
      const { loadScriptAsync, loadCSSSync } = window.wafer.utils

      loadScriptAsync({ src: this.JS }, () => {
        loadCSSSync({ src: this.CSS }, () => {
          // need this custom element for rendering the medicare simplified page when both the script and styles are loaded
          const customTag = document.createElement('aol-medicare-simplified')
          this.medicareElement.appendChild(customTag)
        })
      })
    }
  }

  return MedicareSimplified
})()
